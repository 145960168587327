import { FaWindowClose ,FaUserAlt ,FaWrench,FaShoppingCart } from 'react-icons/fa'
import {Link} from 'react-router-dom'

let SmallNavBar=(props)=>{
return(
    <div className='p-8'>
        <FaWindowClose onClick={props.onClose} className='float-right text-2xl text-red-500'/>
        <h1 className='text-center text-xl text-primaryColor'>Menu</h1>
        <br/>
        <hr className='mt-2'/>
        <ul>
        <li className='mt-2 px-4 py-1 text-secondaryColor'>
                    <Link to="/"   onClick={props.onClose}   className='flex  space-x-4 text-xl'><FaWrench className='mt-1'/> &nbsp; Order Parts</Link>
                     </li>

                     <li className='mt-3 text-secondaryColor px-4 py-1'>
                    <Link to="/cart" className='flex space-x-4 text-xl'   onClick={props.onClose} ><FaShoppingCart className='mt-1'/> &nbsp;  Cart</Link>
                     </li>
                     <li className='mt-3 text-secondaryColor px-4 py-1' >
                    <Link to="/profile"  onClick={props.onClose}  className='flex space-x-4 text-xl'><FaUserAlt className='mt-1'/> &nbsp;  Profile</Link>
                     </li>
        </ul>
    </div>
)
}


export default SmallNavBar