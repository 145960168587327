// import logo from './logo.svg';
import { RouterProvider,redirect, createBrowserRouter } from "react-router-dom";

import Root  from "./pages/Root";

// import Login from './pages/Login'
import ErrorPage from './pages/ErrorPage'
import Home from './pages/Home'
import Cart from "./pages/Cart";


// import Home from "./pages/Home";
import {useSelector} from 'react-redux'

// import {uiActions} from './store/ui-slice'

import {useEffect} from 'react'
import OrderParts from "./pages/OrderParts";
import Login from "./pages/Login";
import PartsByEngine from "./pages/PartsByEngine";
import Profile from "./pages/Profile";




let router=createBrowserRouter([
  {
    path:'/',
    element:<Root/>,
    
    children:[
      {index:true,element:<Home/>},
      // {path:'orderParts',element:<OrderParts />},
      {path:'orderParts/:model',element:<PartsByEngine/>},
      {path:'profile',element:<Profile/>},
      {path:'cart',element:<Cart/>},
     
      
    ]

},
{path:'/login',element:<Login/>},
,{
  path:'*',
  element:<ErrorPage/>
}
])

function App() {
  // let navigate=useNavigate();
 
  // let language=useSelector((state)=>state.ui.language)
  // let categories=useSelector(state=>state.ui.categories)

  // i18n.changeLanguage(language)

 
  // useEffect;

  return (
   <RouterProvider router={router}/>
  );
}

export default App;



