import React from "react"
let Input=React.forwardRef((props,ref)=>{
    return(
        <div>
           
            <input {...props.input} ref={ref} className="outline-none border py-1 pl-2 rounded-md"/>

        </div>

    )
}) 


export default Input