import { configureStore } from "@reduxjs/toolkit";


import uiSlice from "./ui-slice";
import productSlice from './product-slice'
import cartSlice from './cart-slice'


let store = configureStore({
  reducer: { ui: uiSlice.reducer ,product:productSlice.reducer,cart:cartSlice.reducer},
});

export default store;
