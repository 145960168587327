import {useState , useRef} from 'react'
import {useSelector} from 'react-redux' 
import {Link, useParams } from 'react-router-dom'
import axios from 'axios';
import PartDetail from '../components/SpareParts/PartsDetail';
import EngineList from '../components/Engine/EngineList';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { FaSpinner } from "react-icons/fa";
import Modal from '../components/UI/Modal';
import SmallModal from '../components/UI/SmallModal';


let defaultInitialState={
    make:'a',
    engineCode:'',
    showFuelSelect:false,
    fuelType:[],
    fuelArray:[],
    fuelValue:'',
    cc:[],
    showCcSelect:false,
    ccValue:'',
    ccArray:[],
    model:[],
    showModelSelect:false,
    modelValue:'',
    modelArray:[],
    years:[],
    shwoYearsSelect:false,
    yearsValue:'',
    inputValue:'',
    searchByPart:true,
    apiHit:false,
    engineArray:[],
    filterArray:[],
    showAllResult:false
  }


let OrderParts=()=>{

    let dataInitialState=useSelector(state=>state.ui.dataInitialState)

    // let [inputValue,setInputValue]=useState('')
    // let [searchByPart,setSearchByPart]=useState(true)
    let [data,setData]=useState(dataInitialState)
    let baseURL=useSelector(state=>state.ui.baseURL)
    
    let [dataArray,setDataArray]=useState([])
    // let [apiHit,setApiHit]=useState(false)
    let [error,setError]=useState(null)
    let [loading,setLoading]=useState(false)
    let [responseMessage,setResponseMessage]=useState('')
    // let [engineArray,setEngineArray]=useState([]);
    // let [filterArray,setFilterArray]=useState([]);
    // console.log('++++++++ search by',searchBy)

    let engineCodeRef=useRef();


    //  clear search handler

    let clearSearchHandler=()=>{
        setData(defaultInitialState)
        setDataArray([])
        setError(null)
        setLoading(false)
        setResponseMessage('')
    }

    // let dispatch=useDispatch()

   let inputChangeHandler=(e)=>{
    setData({...data,'inputValue':e.target.value})
        // setInputValue(e.target.value)
   }

   let setInputValue=(e)=>{
    setData({...data,'inputValue':e})
   }

   let setApiHit=()=>{
    setData({...data,'apiHit':false})
   }

//    console.log('+++++ data',data)
  

   let vehicleChangeHandler=async (e)=>{
    // setApiHit(false)
    setLoading(true)
    setError(null)
    setData({...data,'apiHit':false,'showAllResult':false,'inputValue':'','engineCode':'','showModelSelect':false,'shwoYearsSelect':false,'showFuelSelect':false,'showCcSelect':false,'make':e.target.value})
    try{
        let response=await axios.get(baseURL+'apis/allEngines?make='+e.target.value)
        if(response.status!==200){
                throw new Error('Something Went Wrong')
        }else{
            // console.log('++++  response ',response)
                if(response.data.code===200){
                    setData({...data,'apiHit':true,'showAllResult':false,'filterArray':response.data.engines,'engineCode':'','engineArray':response.data.engines,'inputValue':'','showModelSelect':false,'shwoYearsSelect':false,'showFuelSelect':false,'showCcSelect':false,'make':e.target.value})
                    setLoading(false)
                    // setEngineArray()
                    // setFilterArray(response.data.engines)
                }else if(response.data.code===404){
                    setData({...data,'apiHit':true,'engineArray':[],'inputValue':'','engineCode':'','showModelSelect':false,'shwoYearsSelect':false,'showFuelSelect':false,'showCcSelect':false,'make':e.target.value})
                    setLoading(false)
                    setResponseMessage(response.data.message)
                    // setEngineArray([])     
                }
                else{
                    setData({...data,'apiHit':true,'inputValue':'','showModelSelect':false,'engineCode':'','shwoYearsSelect':false,'showFuelSelect':false,'showCcSelect':false,'make':e.target.value})
                    setLoading(false)
                    setResponseMessage(response.data.message)
                }
                
            
        }
    }catch(error){
        setError(error.message)
        setLoading(false)
    }
   
    
   }

//     select change handler

let inputFilterChangeHandler=()=>{
    
    // setData({...data,})
    let tempArray=data.engineArray.map(item=>item.fuelType)
    tempArray=[... new Set(tempArray)]
    setData({...data,'fuelType':tempArray,'filterArray':data.engineArray,'engineCode':'I don\'t know','showAllResult':false,'showCcSelect':false,'showModelSelect':false,'shwoYearsSelect':false})
    // setFilterArray(data.engineArray)
  
   }

    let fuelTypeValueChangeHandler=(e)=>{
        // console.log('+++++ vlaue of fuel change',e.target.value)
        let tempArray=[];
        if(e.target.value==='1'){
            tempArray=[...data.engineArray]
        }else{
            tempArray=data.engineArray.filter(item=>item.fuelType===e.target.value)
        }
        
        if(tempArray.length>0&&tempArray.length<6){
            setData({...data,'fuelValue':e.target.value,'filterArray':tempArray,'fuelArray':[...tempArray],'showCcSelect':false,'showModelSelect':false,'shwoYearsSelect':false})
            // setFilterArray(tempArray)
        }else{
            let ccArray=tempArray.map(item=>{return item.cc})
            ccArray=[... new Set(ccArray)]
            ccArray=ccArray.sort();
            // console.log('+++++ ccc',ccArray)
            // setFilterArray(tempArray)
            setData({...data,'fuelValue':e.target.value,'filterArray':tempArray,'showCcSelect':true,'fuelArray':[...tempArray],cc:ccArray,'showModelSelect':false,'shwoYearsSelect':false})
        }
        
    }
    let ccValueChangeHandler=(e)=>{
        // console.log('+++++++ fuel value',data.fuelValue)
        // console.log('+++++++ cc value',e.target.value)
        let tempArray=[];
        if(e.target.value==='1'){
            tempArray=[...data.fuelArray]
        }else{
            tempArray=data.fuelArray.filter(item=>item.cc===e.target.value)
        }
        // let tempArray=engineArray.filter(item=>item.fuelType===data.fuelValue&&item.cc===e.target.value)
       
        if(tempArray.length>0&&tempArray.length<6){
            setData({...data,'ccValue':e.target.value,'filterArray':tempArray,'showModelSelect':false,'shwoYearsSelect':false})
            // setFilterArray(tempArray)
        }else{
            let modelArray=tempArray.map(item=>{return item.model})
            modelArray=[... new Set(modelArray)]
            // console.log('+++++ ccc',ccArray)
            // setFilterArray(tempArray)
            setData({...data,'ccValue':e.target.value,'filterArray':tempArray,'ccArray':[...tempArray],'showModelSelect':true,'model':modelArray,'shwoYearsSelect':false})
        }
       
    }
    let modelValueChangeHandler=(e)=>{

        let tempArray=[];
        if(e.target.value==='1'){
            tempArray=[...data.ccArray]
        }else{
            tempArray=data.ccArray.filter(item=>item.model===e.target.value)
        }

        // let tempArray=engineArray.filter(item=>item.fuelType===data.fuelValue&&item.cc===data.ccValue&&item.model===e.target.value)
       
        if(tempArray.length>0&&tempArray.length<6){
            setData({...data,'filterArray':tempArray,'modelValue':e.target.value,'shwoYearsSelect':false})
            // setFilterArray(tempArray)
        }else{
            let yearsArray=tempArray.map(item=>{return item.years})
            yearsArray=[... new Set(yearsArray)]
            // console.log('+++++ ccc',ccArray)
            // setFilterArray(tempArray)
            setData({...data,'filterArray':tempArray,'modelValue':e.target.value,'modelArray':[...tempArray],'years':yearsArray,'shwoYearsSelect':true})
        }

    }
    let yearsValueChangeHandler=(e)=>{
        let tempArray=[];
        if(e.target.value==='1'){
            tempArray=[...data.modelArray]
        }else{
            tempArray=data.modelArray.filter(item=>item.years===e.target.value)
        }
        setData({...data,'filterArray':tempArray,'yearsValue':e.target.value})
        // setFilterArray(tempArray)
        // let tempArray=engineArray.filter(item=>item.fuelType===data.fuelValue&&item.cc===data.ccValue&&item.model===e.target.value)
       
        // if(tempArray.length>0){
        //     // setData({...data,'modelValue':e.target.value,'shwoYearsSelect':false})
        //     setFilterArray(tempArray)
        // }else{
        //     let yearsArray=tempArray.map(item=>{return item.years})
        //     yearsArray=[... new Set(yearsArray)]
        //     // console.log('+++++ ccc',ccArray)
        //     setFilterArray(tempArray)
        //     setData({...data,'modelValue':e.target.value,'modelArray':[...tempArray],'years':yearsArray,'shwoYearsSelect':true})
        // }
    }

   let submitHandler=async ()=>{
   
    let response=null;
    try{

        if(data.searchByPart){
            setLoading(true)
            response=await axios.get(baseURL+'apis/partsByCode?val='+data.inputValue)
            if(response.status!==200){
                    throw new Error('Something Went Wrong')
            }else{  
                console.log('++++  response outside if',response.data.part)
                    if(response.data.code===200){
                        console.log('++++  response inside if',response.data.part)
                        setLoading(false)
                        setData({...data,'apiHit':true})
                        
                        // setApiHit(true)
                        setDataArray(response.data.part)
                    }else{
                        setLoading(false)
                        setData({...data,'apiHit':true})
                        // setApiHit(true)
                        setResponseMessage(response.data.message)
                    }
                    
                
            }
        }else{
            console.log('$$$$$$$$$',data.engineArray)
            let query=data.engineCode.toUpperCase();
            console.log('------- query $$$$$$$4',query)
            let tempArray=data.engineArray.filter(item=>{return item.code.toUpperCase().includes(query)})
            // console.log('+++ temp array:',tempArray)
            if(tempArray.length>0){
             return  setData({...data,'filterArray':tempArray,'showAllResult':true,'showCcSelect':false,'showModelSelect':false,'shwoYearsSelect':false})  
            //  setFilterArray(tempArray)
            }else{
                // setFilterArray(data.engineArray)
                let tempArray=data.engineArray.map(item=>item.fuelType)
                tempArray=[... new Set(tempArray)]
                return setData({...data,'filterArray':data.engineArray,'showAllResult':false,'fuelType':tempArray,'engineCode':'I don\'t know','showCcSelect':false,'showModelSelect':false,'shwoYearsSelect':false})
            }
        //    console.log('++++++ ref value',engineCodeRef.current.value) 
        }

    }catch(error){
        setError(error.message)
    }
    
    
     
   }

   const items = [
    {
      key: '1',
      label: (
        <li onClick={inputFilterChangeHandler}>  I don't know</li> 
      ),
    },
    
  ];


//    console.log('+++++ engine list ',engineArray)

    return(
        <div className="bg-slate-50 w-full h-full  md:p-6">
            {error&&<p>{error}</p>}
            {loading&&<SmallModal><FaSpinner  className='animate-spin text-primaryColor text-8xl'/></SmallModal>}
            
            <div className="w-full sm:flex space-x-2 flex bg-white shadow-lg p-1 sm:p-3 md:p-6">
                <div className='flex lg:flex-row flex-col space-y-2 space-x-0 md:space-y-0 md:space-x-2'>
                <button className="text-white  rounded-lg bg-secondaryColor lg:p-4  p-2 md:text-lg text-center" onClick={()=>{setData({...data,'searchByPart':!data.searchByPart})}}>Search By<br/>{data.searchByPart?'Vehicle':'Part Number'}  </button>
               
               <div className="flex  flex-col ">
                   <p className="md:mt-4 mt-2 md:text-xl font-bold">{data.searchByPart?'Search By Part Number':'Search By Vehicle'}</p>
                     {data.searchByPart?<input type="text" name="codeInput" value={data.inputValue} onChange={inputChangeHandler} className=" border md:mt-6 mt-1 rounded-md border-black py-1 pl-2" placeholder="e.g abc123"/>:
                     <div className='flex lg:flex-row  lg:space-x-2 flex-col'>
                        <div className=''>
                        <label htmlFor='make' className='block mt-2'>Make</label>
                     <select id="make" onChange={vehicleChangeHandler} value={data.make} name="make" className="p-1 outline-none w-full  md:mt-2  border-black border mt-1 rounded-md">
                     <option value="a">Make</option>
                     <option>ADE ENGINES        </option>
                      <option>ALFA ROMEO         </option>
                      <option>AUDI               </option>
                      <option>AUSTIN             </option>
                      <option>BAIC               </option>
                      <option>BAJAJ              </option>
                      <option>BAW                </option>
                      <option>BEDFORD            </option>
                      <option>BMW                </option>
                      <option>BYD                </option>
                      <option>CADILLAC           </option>
                      <option>CAM -CHINA AUTO MAN</option>
                      <option>CHANA              </option>
                      <option>CHERY              </option>
                      <option>CHEVROLET          </option>
                      <option>CHRYSLER           </option>
                      <option>CITROEN            </option>
                      <option>CMC                </option>
                      <option>DAEWOO             </option>
                      <option>DAIHATSU           </option>
                      <option>DATSUN             </option>
                      <option>DFM (CMF)          </option>
                      <option>DODGE              </option>
                      <option>EICHER             </option>
                      <option>ELEKSA             </option>
                      <option>FAW                </option>
                      <option>FERGUSON           </option>
                      <option>FIAT               </option>
                      <option>FORD               </option>
                      <option>FOTON              </option>
                      <option>FUDI               </option>
                      <option>FUSO               </option>
                      <option>GAZ                </option>
                      <option>GEELY              </option>
                      <option>GONOW              </option>
                      <option>GREAT WALL MOTORS  </option>
                      <option>HAFEI              </option>
                      <option>HAVAL              </option>
                      <option>HILLMAN            </option>
                      <option>HINO               </option>
                      <option>HOLDEN             </option>
                      <option>HONDA              </option>
                      <option>HUMMER             </option>
                      <option>HYUNDAI            </option>
                      <option>INEOS              </option>
                      <option>INFINITI           </option>
                      
                      <option>INTERNATIONAL      </option>
                      
                      <option>ISUZU              </option>
                      <option>IVECO              </option>
                      <option>JAC MOTORS         </option>
                      <option>JAGUAR             </option>
                      <option>JEEP               </option>
                      <option>JINBEI             </option>
                      <option>JMC                </option>
                      <option>KIA                </option>
                      <option>KING LONG          </option>
                      <option>LADA               </option>
                      <option>LANCIA             </option>
                      <option>LAND ROVER         </option>
                      <option>LANDWIND           </option>
                      <option>LEXUS              </option>
                      <option>LEYLAND            </option>
                      <option>LIFAN              </option>
                      <option>LINDE              </option>
                      <option>LISTER             </option>
                      <option>MAHINDRA           </option>
                      <option>MAN                </option>
                      <option>MAXUS              </option>
                      <option>MAZDA              </option>
                      <option>MEIYA              </option>
                      <option>MERCEDES           </option>
                      <option>MG                 </option>
                      <option>MINI               </option>
                      <option>MITSUBISHI         </option>
                      <option>NISSAN             </option>
                      <option>OPEL               </option>
                      <option>PERKINS            </option>
                      <option>PETTER             </option>
                      <option>PEUGEOT            </option>
                      
                      <option>PORSCHE            </option>
                      <option>PROTON             </option>
                      <option>RAMBLER            </option>
                      <option>RENAULT            </option>
                      <option>ROVER              </option>
                      
                      <option>SAAB               </option>
                      <option>SEAT               </option>
                      
                      <option>SMART              </option>
                      <option>SOYAT              </option>
                      <option>SSANG-YONG         </option>
                      <option>SUBARU             </option>
                      <option>SUZUKI             </option>
                      <option>TATA               </option>
                      <option>TOYOTA             </option>
                      <option>TRIUMPH            </option>
                      <option>UD TRUCKS          </option>
                      <option>VAUXHALL           </option>
                      <option>VOLKSWAGEN         </option>
                      <option>VOLVO              </option>
                      <option>WILLYS             </option>
                      <option>ZOTYE              </option>
                     </select>
                        </div>
                        {data.engineArray.length>0&&data.engineArray.length>6&&<div className=''>
                            <label className='block mt-2'>Engine Code</label>
                            
                            <div className='flex '>

                            <input type="text" placeholder='Engine Code'  value={data.engineCode} onChange={(e)=>{setData({...data,'engineCode':e.target.value})}}  className='outline-none p-1 border w-11/12 mt-2 border-black rounded-l-md'/>
                            <Dropdown
    menu={{
      items,
    }}
    placement="bottomRight"
      arrow
  >
    <a onClick={(e) => e.preventDefault()}>
      <Space className='border mt-2  border-black p-1 rounded-r-md'>
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>



  </div>   
                           


                            
                            
                        </div>}
                        {data.engineCode==='I don\'t know' &&<div className=''>
                        <label className='block mt-2'>Fuel</label>
                        <select  onChange={fuelTypeValueChangeHandler} className='p-1  outline-none w-full  md:mt-2  border-black border mt-1 rounded-md'>
                        <option hidden>Choose</option>
                            <option value="1">I don't know</option>
                                {data.fuelType.length>0&&data.fuelType.map(item=>{
                                    return(<option value={item} key={item}>{item==='P'?'P Petrol':item==='D'?'D Diesel':item==='H'?'H Hybrid':'E Electric'}</option>)
                                })}
                        </select>
                        </div>}
                        {data.showCcSelect&&<div>
                        <label className='block mt-2'>Litres (Cc)</label>
                        <select value={data.ccValue} onChange={ccValueChangeHandler} className='p-1 w-full outline-none  md:mt-2  border-black border mt-1 rounded-md'>
                        <option hidden>Choose</option>
                        <option value="1">I don't know</option>
                           {data.cc.map(item=>{
                            return(<option key={item} value={item}>{item}</option>)
                           })}
                            
                        </select>
                        </div>}
                        {data.showModelSelect&& <div>
                        <label className='block mt-2'>Model</label>
                        <select value={data.modelValue} onChange={modelValueChangeHandler} className='p-1 w-full outline-none  md:mt-2  border-black border mt-1 rounded-md'>
                        <option hidden>Choose</option>
                        <option value="1">I don't know</option>
                           {data.model.map(item=>{
                            return(<option key={item} value={item}>{item}</option>)
                           })}
                        </select>
                        </div>}
                        {data.shwoYearsSelect&& <div>
                        <label className='block mt-2'>Years</label>
                        <select onChange={yearsValueChangeHandler} value={data.yearsValue} className='p-1 w-full  outline-none  md:mt-2  border-black border mt-1 rounded-md'>
                        <option hidden>Choose</option>
                            <option value="1">I don't know</option>
                            {data.years.map(item=>{
                            return(<option key={item} value={item}>{item}</option>)
                           })}
                        </select>
                        </div>}
                      
                       
                       
                       
                     </div>
     
                     }
               </div>
                </div>
                
                <div className='flex flex-col space-y-2'>
                    <button className="bg-green-700 px-8 py-5 md:py-1 md:px-8  sm:mt-8 text-white rounded-md" onClick={clearSearchHandler}>Clear</button>
                   
                    <button className="bg-secondaryColor px-8 py-5 md:py-1 md:px-7   text-white  rounded-md" onClick={submitHandler}>Search</button>
                </div>
            </div>
                     {console.log('++++ data array before send',dataArray)}
            {data.searchByPart?data.apiHit&&dataArray.length>0?<PartDetail checkForModal={true} setDataArray={setDataArray} setInputValue={setInputValue} setApiHit={setApiHit} parts={dataArray} inputValue={data.inputValue}/>:<p>{responseMessage}</p>:
            data.apiHit&&data.engineArray.length>0?<EngineList data={data} engines={data.filterArray}/>:<p>{responseMessage}</p>}
        </div>
      
    )
}


export default OrderParts