import { useSelector ,useDispatch } from "react-redux"
import {PlusSquareOutlined ,CloseSquareOutlined, MinusSquareOutlined} from '@ant-design/icons';
import { cartActions } from "../store/cart-slice";
import Modal from "../components/UI/MobileModal";
import {useState} from 'react'
import axios from 'axios'
import SmallModal from "../components/UI/SmallModal";
import { FaSpinner } from "react-icons/fa";

let Cart=()=>{
let cart=useSelector(state=>state.cart)
// console.log('++++ cart--------=========',cart )
let baseURL=useSelector(state=>state.ui.baseURL)
let dispatch=useDispatch();

let user=useSelector(state=>state.ui.user)
// console.log('+++++ user before parse',user)
user=JSON.parse(user)

let [error,setError]=useState(null)
let [loading,setLoading]=useState(false)


//  checkout handler

let checkoutHandler=async()=>{
    
   setError(null);
   setLoading(true);
    let data={
        cart:cart.items,
        user:user._id,
        email:user.email,
        name:user.name
    }

// console.log('++++++++++ reponse',response)
try{
    let response=await axios.post(baseURL+'order/make',data)

    if (response.status !== 200) {
        throw new Error("Something Went Wrong Try Again");
      }
    else  if (response.data.code !== 200) {
        throw new Error(response.data.message);
      }else{
        dispatch(cartActions.clearCart())
        setLoading(false)
      }

}
catch (error) {
    setError(error.message);
    setLoading(false);
  }

}

return(
    <div className="bg-gray-100 w-full md:p-2 lg:p-6">
        {error&&<p>{error}</p>}
            {loading&&<SmallModal><FaSpinner  className='animate-spin text-primaryColor text-8xl'/></SmallModal>}
            <div className="bg-white py-6 px-2 rounded-lg">
            <h1 className="text-center text-primaryColor my-3 text-2xl">Cart</h1>
            {cart.items.length>0&&<table className="w-full table-auto my-2">
                <thead className="text-left  shadow-md text-bold ">
                    <tr> <th className="py-1">Name</th>
                    <th>Brand</th>
                    <th>Quantity</th> 
                    <th>Remove</th> 
                    </tr>
                </thead>
                <tbody>
                {cart.items.map(item=>{
                return(
                    <tr key={item.id}>
                        <td className="py-4">{item.name}</td>
                        <td>{item.brand}</td>
                        <td className="text-xl"><PlusSquareOutlined className="text-blue-500 text-xl"  onClick={()=>{dispatch(cartActions.addExistingItemToCart({item:item}))}}/> {item.quantity} <MinusSquareOutlined  className="text-red-500 text-xl" onClick={()=>{
                            dispatch(cartActions.removeSingleItem({item:item}))
                        }}/></td>
                        <td><CloseSquareOutlined  className="text-red-500 text-xl" onClick={()=>{dispatch(cartActions.removeWholeItem({item:item}))}}/></td>
                    </tr>
                   
                )
            })}

                </tbody>
                
            </table>}
            
            {cart.items.length===0&&<p className="text-red-500 p-5 text-center text-2xl">No Item In Cart</p>}
            <hr className="my-1"/>
           
           <button disabled={cart.items.length===0} className="mx-auto  bg-primaryColor py-2 px-8 mt-4 rounded-lg text-white" onClick={checkoutHandler}>Checkout</button>
            </div>
           
    </div>
)

}

export default Cart