import { createSlice } from "@reduxjs/toolkit";

let productSlice=createSlice({
    name:'product',
    initialState:{
        products:[],
    },
    reducers:{
        fetchAllProduct(state,action){
            state.products=action.payload.products
        },
       
    }
})

export const productActions=productSlice.actions;
export default productSlice;