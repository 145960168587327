import { createSlice } from "@reduxjs/toolkit";

let uiSlice=createSlice({
    name:'ui',
    initialState:{
        token:localStorage.getItem('token'),
        user:localStorage.getItem('user'),
        isLoggedIn:false,
        
        baseURL:'https://server.hashtechcarparts.com/',
        dataInitialState:{
            make:'a',
            engineCode:'',
            showFuelSelect:false,
            fuelType:[],
            fuelArray:[],
            fuelValue:'',
            cc:[],
            showCcSelect:false,
            ccValue:'',
            ccArray:[],
            model:[],
            showModelSelect:false,
            modelValue:'',
            modelArray:[],
            years:[],
            shwoYearsSelect:false,
            yearsValue:'',
            inputValue:'',
            searchByPart:true,
            apiHit:false,
            engineArray:[],
            filterArray:[],
            showAllResult:false
          }
        

    },
    reducers:{
        toggleLanguage(state,action){
            state.language=action.payload.language
        },
        loggedIn(state,action){
        state.token=action.payload.token;
        state.user=action.payload.user;
        state.isLoggedIn=action.payload.isLoggedIn
        },
        logout(state,action){
            state.token='';
        state.user={};
        state.isLoggedIn=false
        localStorage.clear();
        },updateDataInitialState(state,action){
            state.dataInitialState=action.payload.dataInitialState
        }
       
    }
})

export const uiActions=uiSlice.actions;
export default uiSlice;