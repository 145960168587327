import OrderParts from "./OrderParts"


let Home=()=>{

    

    return(
       <OrderParts/>
    )
}


export default Home