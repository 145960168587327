
import {useState ,useEffect, useRef} from 'react';
import Input from './Input'

import Modal from "../UI/Modal";
import PartsModal from "./PartsModal";
import PartsList from "./PartsList";
import { useDispatch } from 'react-redux';

let PartDetail=(props)=>{
    let [cartQuantity,setCartQuantity]=useState(0)
    let dispatch=useDispatch()
    let inputRef=useRef();
    let [showModal,setShowModal]=useState(false)
    let [partsList,setPartsList]=useState(props.parts)
   
    
    let onSelectItem=(manufactureCode,adminCode)=>{
        console.log('+++++ items select')
        setShowModal(false)
        let tempArray=props.parts.filter(item=>item.adminCode===adminCode)
        setPartsList(tempArray)
    }
   

    let onClose=()=>{
        setShowModal(false)
        props.setApiHit();
        props.setInputValue('');
        props.setDataArray([]);
    }
// console.log('+++++ props .parts changed',partsList)
useEffect(()=>{
    if(props.checkForModal){
        let tempArray=props.parts.filter(item=>item.manufactureCode===props.inputValue||item.adminCode===props.inputValue)
        console.log('+++++ tempArray',tempArray)
        if(tempArray.length===0){
         setShowModal(true)
         setPartsList(props.parts)
        }else{
         setPartsList(tempArray)
         
        }
    }else{
        return ;
    }
  
},[props.parts])

    return(
        <div className="bg-white w-full mt-4">
            {showModal&&<Modal><PartsModal onClose={onClose} onSelectItem={onSelectItem} parts={partsList}/></Modal>}
            
            {!showModal&&partsList&&<PartsList parts={partsList}/>}
       
        </div>
    )

}

export default PartDetail




