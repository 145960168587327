// import { FaAlignJustify } from "react-icons/fa";
import { FaAlignJustify ,FaWrench ,FaShoppingCart } from 'react-icons/fa'
import {useState} from 'react'
import { Link } from 'react-router-dom'
import Logo from './Logo'
let SideBar=()=>{

    let [showText,updateText]=useState(false)

    return (
        <div className=''>
           
            <ul className='mt-2' onMouseEnter={()=>{updateText(true)}} onMouseLeave={()=>{updateText(false)}}>
                <li className=' px-4'>{showText?<Logo/>:<FaAlignJustify className='text-2xl mt-4'/>} </li>
                <br/>
                <hr className='mt-0'/>
                <li className='mt-2 hover:bg-primaryColor hover:text-white px-4 py-1'>
                    <Link to="/" className='flex space-x-4 text-xl'><FaWrench className='mt-1'/> {showText&&<span>Order Parts</span>}</Link>
                     </li>

                     <li className='mt-3 hover:bg-primaryColor hover:text-white px-4 py-1'>
                    <Link to="/cart" className='flex space-x-4 text-xl'><FaShoppingCart className='mt-1'/> {showText&&<span>Cart</span>}</Link>
                     </li>
               
            </ul>

        </div>
    )
}


export default SideBar