import { createSlice } from "@reduxjs/toolkit";

let cartSlice=createSlice({
    name:'cart',
    initialState:{
        items:[],
        totalQuantity:0,
        status:1,
        model:null,
    },
    reducers:{
        addModel(state,action){
            console.log('+++++ action.payload ',action.payload);
            state.model=action.payload.model;    
        },
        addItemToCart(state,action){
            let newItem=action.payload.item
            // console.log('-------------- cart payload',state.totalQuantity)
            let exisitingCartItemIndex=state.items.findIndex(item=>item.id===newItem._id)
            let exisitngItem=state.items[exisitingCartItemIndex]
            if(exisitngItem){
                exisitngItem.quantity=exisitngItem.quantity+parseInt(action.payload.quantity) 

            }else{
                state.items.push({
                    id:newItem._id,
                    name:newItem.name,
                    description:newItem.description,
                    quantity:parseInt(action.payload.quantity),
                   manufactureCode:newItem.manufactureCode,
                   adminCode:newItem.adminCode,
                   brand:newItem.brand,
                    // image:newItem.images,

                })
                state.totalQuantity=state.items.length;
            
            }


        },addExistingItemToCart(state,action){
            let newItem=action.payload.item
            // console.log('-------------- cart payload',state.totalQuantity)
            let exisitingCartItemIndex=state.items.findIndex(item=>item.id===newItem.id)
            let exisitngItem=state.items[exisitingCartItemIndex]
            exisitngItem.quantity=exisitngItem.quantity+1;
        },
        removeSingleItem(state,action){
            let newItem=action.payload.item
            console.log('++++++ item',newItem)
            if(newItem.quantity===1){
                
                state.items=state.items.filter(item=>item.id!==newItem.id)

                state.totalQuantity=state.items.length
            }else{

                // state.subTotal=state.subTotal-newItem.sellingPrice
               let  findExisitngItem=state.items.findIndex(item=>item.id===newItem.id)
               let  exisitngItem=state.items[findExisitngItem]
                exisitngItem.quantity--;
                
            }
        },removeWholeItem(state,action){

            let newItem=action.payload.item
            state.items=state.items.filter(item=>item.id!==newItem.id)

            state.totalQuantity=state.items.length

        },clearCart(state,action){
            state.items=[];
        state.totalQuantity=0;
        }

    }
})

export const cartActions=cartSlice.actions;
export default cartSlice;
