import {FaWindowClose } from 'react-icons/fa'
import {useState} from 'react';


let PartsModal=(props)=>{

    let [parts,setParts]=useState(props.parts)
    let [inputSearch,setInputSearch]=useState('')
    let [fillteredParts,setFillteredParts]=useState(props.parts)
    let inputChangeHandler=(e)=>{
        
        let val=e.target.value
        setInputSearch(e.target.value)
        let tempArray=[]
        // tempArray=parts.filter(item=>{return item.manufactureCode.includes(e.target.value)})
        tempArray=parts.filter(item=>{return item.adminCode.includes(e.target.value)||item.name.includes(e.target.value)||item.brand.includes(e.target.value)||item.description.includes(e.target.value)})
        setFillteredParts(tempArray)
    }

    return(
<div>


<div className='flex justify-between space-x-2'>
<input placeholder='Search' value={inputSearch}  onChange={inputChangeHandler} className='p-1 outline-none border border-black rounded-md' />
<h1>Results Counter: <span className='text-primaryColor font-medium'>{fillteredParts.length}</span></h1>
<FaWindowClose onClick={props.onClose} className='text-2xl text-red-500 '/>
</div>
<hr className='my-2'/>

<div className='lg:block hidden'>
<table className='w-full table-auto'>
    <thead className='text-left'>
        <tr>
        <th>
            Part Number
        </th>
        <th>
            Name
        </th>
        <th>
           Brand
        </th>
        <th>
            Description
        </th>
        </tr>
        
    </thead>
    <tbody>
            {fillteredParts.map(item=>{
                // console.log('++++++ arrayy of parts',item)
                return(
                    
                    <tr key={item.adminCode} onClick={(e)=>{props.onSelectItem(item.manufactureCode,item.adminCode)}}>
                        <td>{item.adminCode}</td>
                        <td>{item.name}</td>
                        <td>{item.brand}</td>
                        <td>{item.description}</td>
                    </tr>
                )
            })}
    </tbody>
</table>
</div>
<div className='lg:hidden'>
{fillteredParts.map(item=>{
    return(
        <div className='border border-gray-200 mt-1 p-1'>
                <div className='flex'  onClick={(e)=>{props.onSelectItem(item.manufactureCode,item.adminCode)}}>
                <h1 className='w-1/2'>Part Number:</h1><h1 className='w-1/2'>{item.adminCode}</h1>    
                </div>
                <div className='flex'>
                <h1 className='w-1/2'>Name:</h1><h1 className='w-1/2'>{item.name}</h1>    
                </div>
                <div className='flex'>
                <h1 className='w-1/2'>Brand:</h1><h1 className='w-1/2'>{item.brand}</h1>    
                </div>
                <div className='flex'>
                <h1 className='w-1/2'>Description:</h1><h1 className='w-1/2'>{item.description}</h1>    
                </div>
                
                
                            
        </div>
    )
})}
</div>
    
</div>
    )
}

export default PartsModal