import {    useNavigate } from "react-router-dom"

import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { uiActions } from "../../store/ui-slice";
import { Collapse } from 'antd';
import { useState,useEffect } from "react";
import {ExportOutlined} from '@ant-design/icons';


let EngineList=(props)=>{

    let dispatch=useDispatch()
    let navigate=useNavigate();

    let [items,setItems]=useState([])

    useEffect(()=>{
        if(props.engines.length>0){
            let tempArray=props.engines.map(item=>{
                return {
                    key: item._id,
                    label: item.model+' / '+item.code+' / '+item.years,
                    children: <div className="border-b border-gray-400 pb-2"><p><span className="font-bold w-1/2">Body</span><span className="w-1/2 float-right">{item.body}</span></p>
                    <p><span className="font-bold w-1/2">CC</span><span className="w-1/2 float-right">{item.cc}</span></p>
                    <p><span className="font-bold w-1/2">CYL</span><span className="w-1/2 float-right">{item.cyl}</span></p>
                    <p><span className="font-bold w-1/2">Engine Code</span><span className="w-1/2 float-right">{item.code}</span></p>
                    <p><span className="font-bold w-1/2">F</span><span className="w-1/2 float-right">{item.fuelType}</span></p>
                    <p><span className="font-bold w-1/2">Bore</span><span className="w-1/2 float-right">{item.bore}</span></p>
                    <p><span className="font-bold w-1/2">Cam</span><span className="w-1/2 float-right">{item.cam}</span></p>
                    <p><span className="font-bold w-1/2">KW</span><span className="w-1/2 float-right">{item.kw}</span></p>
                    <p><span className="font-bold w-1/2">Parts</span><span className="w-1/2 float-right"><ExportOutlined className="text-blue-600 text-lg" onClick={()=>{dispatch(cartActions.addModel({model:item})); dispatch(uiActions.updateDataInitialState({dataInitialState:props.data})); navigate('/orderParts/'+item._id);}}/></span></p>
                    </div>,
                  }
            })
            setItems(tempArray)
        }
    },[props.engines])

    return(
        <div>
                {console.log('+++ props recieved',props.engines)}
                {props.engines.length>0&&props.data.showAllResult?<>
                    <div className="md:hidden px-2 py-4">
                    <Collapse accordion expandIconPosition={'end'}  ghost  items={items}/>
               
                   
                </div>
                <div className="hidden md:block">
                <table className="table-auto w-full">
                    <thead className="text-left shadow-lg">
                        <tr>
                            <th className="py-1">Model Code</th>
                            <th>Body</th>
                            <th>CC</th>
                            <th>Years</th>
                            <th>Engine Code</th>
                            <th>Cyl</th>
                            <th>F</th>
                            <th>Cam</th>
                            <th>Bore</th>
                            <th>KW</th>
                        </tr>
                    </thead>
                    <tbody>
                            {props.engines.map(item=>{
                                return(
                                    <tr key={item._id} onClick={()=>{dispatch(cartActions.addModel({model:item})); dispatch(uiActions.updateDataInitialState({dataInitialState:props.data})); navigate('/orderParts/'+item._id);}}>
                                        
                                    <td>{item.model}</td>
                                    <td>{item.body}</td>
                                    <td>{item.cc}</td>
                                    <td>{item.years}</td>
                                    <td>{item.code}</td>
                                    <td>{item.cyl}</td>
                                    <td>{item.fuelType}</td>
                                    <td>{item.cam}</td>
                                    <td>{item.bore}</td>
                                    <td>{item.kw}</td>
                                    
                                </tr>
                                    
                                )
                            })}
                    </tbody>
                </table>
            </div>
                </>:props.engines.length<6?<>
                <div className="md:hidden px-2 py-4">
                    <Collapse accordion expandIconPosition={'end'}  ghost  items={items}/>
               
                   
                </div>
                <div className="hidden md:block">
                <table className="table-auto w-full">
                    <thead className="text-left shadow-lg">
                        <tr>
                            <th className="py-1">Model Code</th>
                            <th>Body</th>
                            <th>CC</th>
                            <th>Years</th>
                            <th>Engine Code</th>
                            <th>Cyl</th>
                            <th>F</th>
                            <th>Cam</th>
                            <th>Bore</th>
                            <th>KW</th>
                        </tr>
                    </thead>
                    <tbody>
                            {props.engines.map(item=>{
                                return(
                                    <tr key={item._id} onClick={()=>{dispatch(cartActions.addModel({model:item})); dispatch(uiActions.updateDataInitialState({dataInitialState:props.data})); navigate('/orderParts/'+item._id);}}>
                                        
                                    <td>{item.model}</td>
                                    <td>{item.body}</td>
                                    <td>{item.cc}</td>
                                    <td>{item.years}</td>
                                    <td>{item.code}</td>
                                    <td>{item.cyl}</td>
                                    <td>{item.fuelType}</td>
                                    <td>{item.cam}</td>
                                    <td>{item.bore}</td>
                                    <td>{item.kw}</td>
                                    
                                </tr>
                                    
                                )
                            })}
                    </tbody>
                </table>
            </div></>:<p className="text-lg mt-5 text-center">Too many results. Please enter more search criteria.</p>}
       
            

        </div>
    )

}


export default EngineList