import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
// import { useEffect } from "react";
import { useEffect } from "react";
import {useDispatch} from 'react-redux'
import SideBar from "../components/Header/SideBar";
import { uiActions } from "../store/ui-slice";
import { useNavigate } from "react-router-dom";
// import i18n from "i18next";

let Root = () => {


  let dispatch=useDispatch()
  let navigate=useNavigate()

  useEffect(() => { 
    let token=localStorage.getItem('token');
    let user=localStorage.getItem('user');
    // console.log('+++++ token',token)
    if(token){
      let expireIn=localStorage.getItem('expireIn')
    let time=new Date().getTime()
    console.log('++++++ expire time',expireIn);
    console.log('-------- current time',time)
    if(time<expireIn)
    dispatch(uiActions.loggedIn({token:token,user:user,isLoggedIn:true}))
      return ;
    }else{
      // console.log('+++ else working')
      navigate('/login')
    }
    
    
  }, [])
 
  return (
    <div className="w-full overflow-hidden">
      <Header />
     <div className="w-full flex">
      <div className="md:block hidden">
        <SideBar/>
      </div>
      <div className="w-full">
      <Outlet />
      </div>

     </div>

      

      
    </div>
  );
};

export default Root;
