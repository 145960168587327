import {FaAlignJustify } from 'react-icons/fa'
import { useState } from 'react';
import Modal from './../UI/MobileModal'
import SmallNavBar from './SmallNavBar';
import { Badge ,Avatar } from 'antd';
import {ShoppingCartOutlined,UserOutlined} from '@ant-design/icons';
import {Link , useNavigate } from 'react-router-dom'

import { Dropdown, Space } from 'antd';
 
import { useSelector , useDispatch } from 'react-redux';
import { uiActions } from '../../store/ui-slice';



let Header=()=>{
    let [showModal,setShowModal]=useState(false)
    let cartQuantity=useSelector(state=>state.cart.totalQuantity)
    let user=useSelector(state=>state.ui)

    let dispatch=useDispatch();
    let navigate=useNavigate();
    console.log('++++ user------ in header',user)
    let onClose=()=>{
        setShowModal(false)
    }


    const items = [
        {
          key: '1',
          label: (
            <li className='px-5 text-lg py-1 text-primaryColor'><Link to="/profile">Profile</Link></li>
           
          ),
        },
        {
            key: '2',
            label: (
             <li className='px-5 text-lg py-1 text-primaryColor' onClick={()=>{dispatch(uiActions.logout()); navigate('/login')}}>Logout</li>
            ),
          },
       
      ];

    return(
        <div className="w-full  bg-primaryColor py-3 text-white flex justify-between">
            {showModal&&<Modal><SmallNavBar onClose={onClose}/></Modal>}
            <div className='flex'>
                <FaAlignJustify className='md:hidden text-xl ml-3 mt-2' onClick={()=>{setShowModal(true)}}/>
            <h1 className="ml-8 font-bold text-2xl">Order Parts</h1>
            </div>
            
            <div className="mr-8 space-x-4 flex">
            
                <div><Link to="/cart"><Badge size="small" count={cartQuantity}>
                <ShoppingCartOutlined  className='text-4xl text-white'/>
                
    </Badge></Link> </div>
                <div >
                <Dropdown
    menu={{
      items,
    }}
  >
                <UserOutlined   className=' text-4xl ml-3'/>
                </Dropdown>
                
                </div>
                
            </div>

        </div>
    )

}

export default Header