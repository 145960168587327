import {useState,useEffect,useCallback} from 'react'
import { useSelector } from 'react-redux';
import { useParams , useNavigate } from 'react-router-dom';
import axios from 'axios';
import PartDetail from '../components/SpareParts/PartsDetail';
// import { browserHistory } from 'react-router'


let PartsByEngine=()=>{

    let baseURL=useSelector(state=>state.ui.baseURL)
    let model=useSelector(state=>state.cart.model)
    // let {model}=useParams();
    let [partsList,setPartsList]=useState([])
    let [loading,setLoading]=useState(false)
    let [error,setError]=useState(null)
    let navigate=useNavigate();
    // let history=useHistory();

    // console.log('+++++++ model ---',model)

    let getParts = useCallback(async () => {
        setError(null);
        try {
          let response = await axios.get(baseURL+"apis/partsbyengine?model="+model._id);
        //   console.log('++++++ response',response)
          if (response.status !== 200) {
            throw new Error("Something Went Wrong Try Again");
          }
          if (response.data.code !== 200) {
            throw new Error(response.data.message);
          }
        //   console.log('+++++++data ---',response.data)
          setPartsList(response.data.parts);
          setLoading(false);
        } catch (error) {
          setError(error.message);
        }
      }, []);



      useEffect(() => {
        if(model){
            getParts();
        }else{
            navigate('/')     
        }
       
        
      }, [getParts]);

    return(
       <>
       {model&&<div className='bg-gray-100 m-2 md:m-4 lg:m-6'>
      
        <div className='md:block hidden '>
            <div className='flex justify-between p-4'>
                <img src={'https://hashtechcarparts.s3.amazonaws.com/'+model.image+''} className='w-32 h-28' alt={model.image}/>
                <div>
                    <h1 className='font-bold'>Fuel Type</h1>
                    <h1>{model.fuelType}</h1>
                </div>
                <div>
                    <h1 className='font-bold'>CC</h1>
                    <h1>{model.fuelType}</h1>
                </div>
                <div>
                    <h1 className='font-bold'>Cyl</h1>
                    <h1>{model.cyl}</h1>
                </div>
                <div>
                    <h1 className='font-bold'>Body</h1>
                    <h1>{model.body}</h1>
                </div>
                <div>
                    <h1 className='font-bold'>Years</h1>
                    <h1>{model.years}</h1>
                </div>
            </div>
        </div>
        <div className='md:hidden p-3'>
        <div className='flex'>
                    <h1 className='font-bold w-1/2'>Model</h1>
                    <h1 className=''>{model.model}</h1>
                </div>
                 <div className='flex'>
                    <h1 className='font-bold w-1/2'>Fuel Type</h1>
                    <h1>{model.fuelType}</h1>
                </div>
                <div className='flex'>
                    <h1 className='font-bold w-1/2'>CC</h1>
                    <h1>{model.cc}</h1>
                </div>
                <div className='flex'>
                    <h1 className='font-bold w-1/2'>Cyl</h1>
                    <h1>{model.cyl}</h1>
                </div>
                
                <div className='flex'>
                    <h1 className='font-bold w-1/2'>Years</h1>
                    <h1>{model.years}</h1>
                </div>
        </div>


        {partsList&&partsList.length>0?<PartDetail checkForModal={false} parts={partsList}/>:<p>No Parts Found</p>}
        </div>
        
        }
       </>
    )

}

export default PartsByEngine