import CartForm from "./CartForm";

import { useDispatch } from "react-redux"
import { cartActions } from "../../store/cart-slice"
import { useState } from "react";

let PartsList=(props)=>{
    let dispatch=useDispatch();
    let [partsList,setPartsList]=useState(props.parts)
    let DispathCart=(item,quantity)=>{
        dispatch(cartActions.addItemToCart({item:item,quantity:quantity}))
    }

    let [inputValue,setInputValue]=useState('')

    let searchValueChangeHandler=(e)=>{
            let tempArray=[];
            tempArray=props.parts.filter(item=>item.adminCode.includes(e.target.value)||item.name.includes(e.target.value)||item.brand.includes(e.target.value)||item.description.includes(e.target.value))
            setPartsList(tempArray);
            setInputValue(e.target.value)
    }

    return(<div>

<div className="flex p-4 space-x-4">
    <input type="text" value={inputValue} onChange={searchValueChangeHandler} className="p-1 border border-black rounded-md" placeholder="Search"/>
    {inputValue!==''&&<button className="py-1 px-8 hover:border border-black font-bold bg-primaryColor text-white hover:text-primaryColor hover:bg-white rounded-md" onClick={()=>{setInputValue('');setPartsList(props.parts)}}>Clear</button>} 
</div>

{partsList.length>0?partsList.map((item,key)=>{
                return(
                    <div className="md:flex md:space-x-4 p-2 lg:p-4 w-full border-b border-black" key={key}>
                        <div>
                         <h1>Part No. <span className="font-bold"> {item.adminCode}</span></h1> 
                         {console.log('++++++ iamge url',item.image)}  
                        <img src={'https://hashtechcarparts.s3.amazonaws.com/'+item.image+''} className="w-56 h-36" alt={item.image}/>
                            </div>
                         <div>
                         <h1 className="font-bold text-xl">{item.name}</h1>
                         <h1><span className="font-bold">Brand: &nbsp;</span> {item.brand}</h1>
                        
                         <hr/>
                         <h1 className="font-bold">Description</h1>
                         <pre className="">{item.description}</pre>

                         <div className="lg:flex md:space-x-4 ">
                           <CartForm item={item} DispathCart={DispathCart} />
                         </div>
                         </div>
                        
                      
                        </div>
                )
            }):<p className="text-lg text-red-500 p-2">No Parts Found</p>}

    </div>)

}


export default PartsList