import logo from '../assets/images/logo.jpg';
import {useState} from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { redirect ,useNavigate } from 'react-router-dom';
import backgroundImage from '../assets/images/backgroundImage.jpg';

import { FaSpinner } from "react-icons/fa";
// import Modal from '../components/UI/Modal';
import SmallModal from '../components/UI/SmallModal';



let initialState={
    email:'',
    password:''
}

let Login=()=>{

let [data,setData]=useState(initialState);
let [error,setError]=useState(null);

let [loading,setLoading]=useState(false)

const navigate = useNavigate();


let baseURL=useSelector(state=>state.ui.baseURL)

let onChangeHandler=(e)=>{
setData({...data,[e.target.name]:e.target.value})
}

let submitHandler=async (e)=>{
    setError(null)
    setLoading(true)
    // setError(null)
e.preventDefault();



try{


let response=await axios.post(baseURL+'login',data)
   

    //    console.log('+++++ response',response)
       if(response.status===422){
        
        throw new Error('Format of Input fields is incorect')
       }
        else if(response.status!==200){
                throw new Error('Something Went Wrong')
        }else{
           if(response.data.code===200){
            setLoading(false)
                let time=new Date().getTime()
            //    console.log('++++ api hit',response.data.user)
                localStorage.setItem('token',response.data.token)
                localStorage.setItem('expireIn',time+(2*60*60*1000))
                // localStorage.setItem('user',response.data.user)
                // console.log('+++++ user ++++++++++',response.data.user)
                localStorage.setItem('user',JSON.stringify(response.data.user))
               return  navigate('/');
                // return redirect('/cart');
           }else{
            throw new Error(response.data.message)
           } 
        }


}catch(error){
    setLoading(false)
    setError(error.message)
}

}



return(
<div  className='p-4  bg-cover h-screen bg-no-repeat bg-[url(https://hashtechcarparts.s3.amazonaws.com/parts/ee16f790-09c3-11ef-a0aa-a3a156f7c4a7.png)]  lg:bg-[url(https://hashtechcarparts.s3.amazonaws.com/parts/ca5649a0-09c3-11ef-a0aa-a3a156f7c4a7.png)]'  >
{loading&&<SmallModal><FaSpinner  className='animate-spin text-primaryColor text-8xl'/></SmallModal>}
{error&&<p className='border border-red-600 px-4  text-red-500 font-bold py-1 text-center'>{error}</p>}
        <div className='w-full mt-40 lg:ml-14 lg:mt-56 md:w-1/2 lg:w-2/5 '>
        {/* <div className='lg:hidden'><img src={logo} alt="logo" className='w-3/4'/></div> */}
        <form className='px-2 rounded-md bg-gray-50 py-2' onSubmit={submitHandler}>
            <div className='p-4 bg-primaryColor space-y-2'>

           
        <input  type="email" value={data.email} name="email" onChange={onChangeHandler} required className='outline-none border w-full py-1 px-2 rounded-md' placeholder="email"/>
            
        <input  type="password" value={data.password} name="password" onChange={onChangeHandler}  required className='outline-none border w-full py-1 px-2 rounded-md' placeholder="password"/>

            </div>
            
       
        <button type='submit' className='mx-auto bg-primaryColor py-1 text-xl px-6  text-white mt-3'>Login</button>
        </form>
        <p className='mt-2 px-2'>If you don't have account please contact us at <span className='text-primaryColor text-lg'>ceo@hashtechcarparts.com</span></p>
        </div>
        
        



    </div>
)

}

export default Login