import { useSelector } from "react-redux"
import { FaUserCircle } from "react-icons/fa";
let Profile=()=>{

    let isLoggedIn=useSelector(state=>state.ui.isLoggedIn)
    let user=useSelector(state=>state.ui.user)
    console.log('+++++ user before parse',user)
    user=JSON.parse(user)
return(
    <div className="w-full px-4 md:px-6">
        <div className="flex justify-center mt-4 md:mt-8 lg:mt-12"><FaUserCircle  className="mt-5 text-center text-8xl text-primaryColor"/></div>
        
        {console.log('++++ user -- received ==',user)}
        {isLoggedIn&&<div className="mt-2 text-center">
           
        
            <h1 className="mt-4">{user.name}</h1>
            <h1 className="mt-2">{user.email}</h1>
            <h1 className="my-2">{user.mobileNumber}</h1>
            <h1>{user.address}</h1>
            <button className="px-4 py-1 border border-black rounded-md text-primaryColor hover:bg-primaryColor hover:text-white my-2">Chnage Password</button>

            </div>}
    </div>
)
}

export default Profile