import logo from './../../assets/images/logo.jpg'

let Logo=()=>{
return(
    <>
<img src={logo} width="200px" height="75px" alt='Vclinic' className='logo' /> 
</>
)
}

export default Logo