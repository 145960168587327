import { useRef } from "react";
import Input from './Input'
let CartForm=(props)=>{
    let inputRef=useRef();
    let item=props.item
    
    let submitHandler=(event)=>{
        event.preventDefault();
         let enteredValue=inputRef.current.value;
         props.DispathCart(item,enteredValue)
         console.log('++++++ entered value',enteredValue)
        
     }

    return(
        <form onSubmit={submitHandler} className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-3 space-x-0">
        <Input  ref={inputRef}
input={{

id:item._id,
type:'number',
min:'1',
// max:'5',
defaultValue:'1',

}}
/>
         {/* <input type="number" ref={inputRef} /> */}
        {/* <input type="number" name={item.name} value={cartQuantity} onChange={(e)=>{setCartQuantity(e.target.value)}} className="outline-none px-2 py-1 border border-black rounded-lg"/> */}
        <button  className="bg-secondaryColor text-white py-1 px-3  lg:mt-0 rounded-lg">Add to cart</button>
        </form>
    )
}

export default CartForm